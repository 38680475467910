<template>
  <div class="login-container">
    <div class="login-weaper animated bounceInDown">
      <div class="login-logo">
        <img src="/img/logo.png"
             alt="" />
      </div>
      <p class="login-tip">{{  getTitle }} </p>
      <div class="login-border">
        <div class="login-main">
          <el-form ref="loginForm"
                   :rules="loginRules"
                   :model="loginForm"
                   class="login-form"
                   status-icon
                   label-width="0">
            <el-form-item prop="username">
              <el-input v-model="loginForm.username"
                        size="small"
                        auto-complete="off"
                        placeholder="请输入用户名"
                        @keyup.enter.native="handleLogin">
                <i slot="prefix"
                   class="el-icon-user" />
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input v-model="loginForm.password"
                        size="small"
                        auto-complete="off"
                        placeholder="请输入密码"
                        show-password
                        @keyup.enter.native="handleLogin">

                <i slot="prefix"
                   class="el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary"
                         size="small"
                         class="login-submit"
                         @click.native.prevent="handleLogin">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '@/settings'
export default {
  name: 'Login',
  computed: {
    getTitle () {
      return Config.title;
    }
  },
  data () {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }]
      },
      loading: false,
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    handleLogin () {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.$store.dispatch('Login', this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || '/' })
          }).catch((err) => {
            this.$message.error('用户名密码错误')
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style  lang="scss">
@import "@/assets/styles/login.scss";
</style>
